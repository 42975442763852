import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import autoBind from 'react-autobind';

import {Tab, Segment, Grid, Input, Header, Icon, Message, Form, Popup, Divider, Button, Table, Modal, ModalActions, ModalHeader, ModalContent} from 'semantic-ui-react';

import APIConnector from '../../utilities/apiConnector.js';
import * as APPENV from '../../../env.json';

export class Scores extends React.Component{
    constructor(props){
        super();
        this.state = {
            appid: null,
            eventid: null,
            externalid: null,
            firstname: null,
            lastname: null,
            showmodal: false,
            selectedtestevent: null,
            inputDate: "",
            startDate: null,
            endDate: null,
        }

        autoBind(this);
    }

    updateSearchParam(event, data){
        const newstate = this.state;
        if(data.forprop == 'startDate' || data.forprop == "endDate"){
            const newDate = new Date(data.value);
            newstate[data.forprop] = newDate.toISOString();
        }
        else{
            newstate[data.forprop] = data.value;
        }
        this.setState({
            ...newstate
        });
    }

    handleInputChange(e) {
        this.setState({inputDate: e.target.value});
    }

    async getScores(){
        try{
            const requestprops = {
                appid: this.state.appid,
                eventid: this.state.eventid,
                hash: this.state.hash,
                externalid: this.state.externalid,
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
            }
            const scoresrequest = await this.props.getScores(requestprops);
        }
        catch(err){
            console.log(err);
        }
    }

    togglemodal(){
        this.setState((prevState) => ({
            showmodal: !prevState.showmodal,
        }));
    }

    selectTestEvent(eventid){
        this.setState({selectedtestevent: eventid, selectedapp: null});
    }

    selectApp(appid){
        this.setState({selectedapp: appid});
    }

    downloadScoreCSV(){
        try{
            APIConnector.downloadScoreCSV(this.state.eventid);
        }catch(err){
            this.setState({
                error: {
                    type: 101,
                    message: 'Please make sure you have enabled popups for this url'
                }
            });
        }
    }

    async deleteTest(externalid){
        const score = externalid.filter(score=>score.testevent_id===this.state.selectedtestevent && score.appid===this.state.selectedapp)[0];
        try{
            await APIConnector.deleteTest(score.external_id, score.hash);
        }catch(err){
            this.setState({
                error: {
                    type: 101,
                    message: 'Please try again.'
                }
            });
        }
        await this.getScores();
        this.setState({selectedapp: null});
        this.togglemodal();
    }

    async alterDate(externalid) {
        const score = externalid.filter(score=>score.testevent_id===this.state.selectedtestevent && score.appid===this.state.selectedapp)[0];
        const [year, month, day] = this.state.inputDate.split("-");
        const newDate = new Date(year, month - 1, day);
        const isoDate = newDate.toISOString();
        try{
            await APIConnector.reDateTest(score.external_id, score.hash, isoDate);
        }catch(err){
            this.setState({
                error: {
                    type: 101,
                    message: 'Please try again.'
                }
            });
        }
        await this.getScores();
    }

    handleDateChange(e) {
        this.setState({inputDate: e.target.value});
    }

    render(){
        const { appProps:{scores} } = this.props;

        return(
            <React.Fragment>
                <Segment attached>
                    <Message info>
                        <Message.Header>Search Scores</Message.Header>                        
                    </Message>
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <Input forprop="eventid" fluid placeholder='Test Event Id' onChange={this.updateSearchParam} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input forprop="hash" fluid placeholder='Hash' onChange={this.updateSearchParam} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input forprop="externalid" fluid placeholder='External Id' onChange={this.updateSearchParam} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input forprop="firstname" fluid placeholder='First Name' onChange={this.updateSearchParam} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input forprop="lastname" fluid placeholder='Last Name' onChange={this.updateSearchParam} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input forprop="startDate" fluid placeholder='mm/dd/yyyy' type='date' onChange={this.updateSearchParam} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input forprop="endDate" fluid placeholder='mm/dd/yyyy' type='date' onChange={this.updateSearchParam} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Button color="blue" onClick={this.getScores}>Search</Button>
                                <Button onClick={this.downloadScoreCSV} circular icon='cloud download' color='green'></Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                {scores ?
                    <Segment attached>
                        <p>Available Test Events</p>
                        {[...new Set(scores.map(score=>score.testevent_id))]
                        .map(testeventid=>{
                            const testevent = scores.find(score=>score.testevent_id===testeventid)
                            return (
                                <Button key={`score-testevent-button-${testeventid}`} size='large' color="blue" basic={testeventid!==this.state.selectedtestevent} onClick={()=>this.selectTestEvent(testeventid)}>
                                    {testevent && testevent.event_title ? testevent.event_title : "No Title Available"}
                                    <br />
                                    <small className="smallid">{testeventid}</small>
                                </Button>
                            )
                        })}
                        {this.state.selectedtestevent?
                            <React.Fragment>
                                <Divider />
                                <p>Students</p>
                                {[...new Set(scores.filter(score=>score.testevent_id===this.state.selectedtestevent).map(score=>score.appid))]
                                .map(appid=>{
                                    const student = scores.find(score=>score.appid===appid)
                                    return (
                                        <Button key={`score-student-button-${appid}`} size='large' color="blue" basic={appid!==this.state.selectedapp} onClick={()=>this.selectApp(appid)}>
                                            {student && student.firstname ? student.firstname : "No Firstname"}&nbsp;
                                            {student && student.lastname ? `${student.firstname?' ':''}${student.lastname}` : "No Lastname"}
                                            <br/>
                                            <small className="smallid">{student && student.external_id ? `External ID: ${student.external_id}` : "No External Id"}</small>
                                            <br />
                                            <small className="smallid">App ID: {appid}</small>
                                        </Button>
                                    )
                                })}
                            </React.Fragment>
                        :null}
                        {this.state.selectedtestevent && this.state.selectedapp?
                            <React.Fragment>
                                <Divider />
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column floated='left'>
                                            <Header sub>Score</Header>
                                            <span>
                                                {scores.filter(score=>score.testevent_id===this.state.selectedtestevent && score.appid===this.state.selectedapp).map(score=>isNaN(score.correct)?0:score.correct).reduce((acc, val)=>acc+val, 0)}
                                                /
                                                {scores.filter(score=>score.testevent_id===this.state.selectedtestevent && score.appid===this.state.selectedapp).map(score=>isNaN(score.total)?1:score.total).reduce((acc, val)=>acc+val, 0)}
                                            </span>
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            Final Rating
                                            <h3>
                                                {scores.filter(score=>score.testevent_id===this.state.selectedtestevent && score.appid===this.state.selectedapp)[0].rating == "undefined" ? "N/A" :
                                                scores.filter(score=>score.testevent_id===this.state.selectedtestevent && score.appid===this.state.selectedapp)[0].rating}
                                            </h3>
                                            <Popup content='Final Rating After Completing Test Event' trigger={
                                                <Icon size="small" link name="exclamation circle" color="blue"/>
                                            }/>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <div className="ui action input">
                                                <input type="text" value={this.state.inputDate} onChange={this.handleDateChange} placeholder="mm/dd/yyyy" type="date"/>
                                                <button className="ui button" onClick={() => this.alterDate(scores)}>Change Date</button>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column floated='right'>
                                            <Popup content='Send Score To Student' trigger={
                                                <Icon size="large" link name="paper plane" color="blue"/>
                                            }/>
                                            <Icon size="large" name="times circle outline" color="red" onClick={this.togglemodal} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                
                                <Table compact celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Item</Table.HeaderCell>
                                            <Table.HeaderCell>Answer</Table.HeaderCell>
                                            <Table.HeaderCell>{scores.filter(score=>score.testevent_id===this.state.selectedtestevent && score.appid===this.state.selectedapp && (score.type === "text" || score.type === "audio")).length ? "Score": "Is Correct"}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {[...new Set(scores.filter(score=>score.testevent_id===this.state.selectedtestevent && score.appid===this.state.selectedapp).map(score=>score.item_title/**must map item_id */))]
                                        .map((itemid, item_index)=>{
                                            const score_item = scores.find(score=>score.testevent_id===this.state.selectedtestevent && score.appid===this.state.selectedapp && score.item_title===itemid/**must map item_title*/);
                                            return (
                                                <Table.Row key={`score-row-${this.state.selectedapp}-${this.state.selectedtestevent}-${item_index}`}>
                                                    <Table.Cell>
                                                        {score_item.item_title ? score_item.item_title : 'Item Title Unavailable'}
                                                        <br/>
                                                        <small className="smallid">{/*itemid*/}</small>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {score_item.option_text ? score_item.option_text : score_item.student_answer}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {score_item.type === "text" || score_item.type === "audio" ? 
                                                            score_item.correct :
                                                            <Popup content={score_item.correct ? "Correct" : "Incorrect"} trigger={
                                                                <Icon name={score_item.correct ? "check" : "times"} color={score_item.correct ? "green" : "red"}/>
                                                            }/>}
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>
                                </Table>
                            </React.Fragment>
                        :null}
                    </Segment>
                :null}
                <Modal
                    size="tiny"
                    open={this.state.showmodal}
                    onClose={this.togglemodal}
                >
                    <ModalHeader>Delete Student Test</ModalHeader>
                    <ModalContent>
                    <p>Are you sure you want to delete this test?</p>
                    </ModalContent>
                    <ModalActions>
                    <Button negative onClick={this.togglemodal}>
                        No
                    </Button>
                    <Button positive onClick={()=>this.deleteTest(scores)}>
                        Yes
                    </Button>
                    </ModalActions>
                </Modal>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Scores));