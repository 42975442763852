import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import autoBind from 'react-autobind';

import {Tab, Segment, Grid, Input, Header, Icon, Message, Form, Popup, Divider, Button, Table, Dropdown} from 'semantic-ui-react';

import * as APPENV from '../../../env.json';
import { getCollectionStatuses } from '../../datastore/actions/appactions.js';
import APIConnector from '../../utilities/apiConnector.js';

export class TestletsReport extends React.Component{
    constructor(props){
        super();
        this.state = {
            eventid: null,
            statusid: null,
        }

        autoBind(this);
    }

    componentDidMount(){
        this.getStatuses();
    }

    async getStatuses(){
        const {appProps: {status}} = this.props;

        if(!status.collections){
            const statuses = await this.props.getCollectionStatuses();
            this.setState({collectionstatuses: statuses});
        }
    }

    updateSearchParam(event, data){
        const newstate = this.state;
        if(data.forprop == 'startDate' || data.forprop == "endDate"){
            const newDate = new Date(data.value);
            newstate[data.forprop] = newDate.toISOString();
        }
        else{
            newstate[data.forprop] = data.value;
        }
        this.setState({
            ...newstate
        });
    }

    updateTestletStatus(e, {value}){
        this.setState((prevState)=>({
            ...prevState,
            statusid: value
        }));
    }

    async getTestletsReport(){
        try{
            const requestprops = {
                statusid: this.state.statusid,
                eventid: this.state.eventid,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
            }
            const itemreportrequest = await this.props.getTestletsReport(requestprops);
        }
        catch(err){
            console.log(err);
        }
    }

    downloadTestletsReportCSV(){
        try{
            var params = APIConnector.objToQueryString({
                eventid: this.state.eventid,
                statusid: this.state.statusid
            });
            APIConnector.downloadTestletCSV(params);
        }catch(err){
            this.setState({
                error: {
                    type: 101,
                    message: 'Please make sure you have enabled popups for this url'
                }
            });
        }
    }
    render(){
        const { appProps:{testletsreport, status} } = this.props;
        return(
            <React.Fragment>
                <Segment attached>
                    <Message info>
                        <Message.Header>Search Testlet Usage</Message.Header>
                    </Message>
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <Input forprop="eventid" fluid placeholder='Test Event Id' onChange={this.updateSearchParam} />
                            </Grid.Column>
                            <Grid.Column>
                                <Dropdown
                                    floated='right'
                                    onChange={this.updateTestletStatus}
                                    search
                                    selection
                                    placeholder='Status'
                                    options={
                                        status.collections?status.collections
                                        .filter(status=>!(status.default && !status.can_use && !status.searchable))
                                        .map(status=>{
                                            return {
                                                key: status.id,
                                                value: status.id,
                                                text: status.status
                                            }
                                        }): null
                                    }
                                    value={this.state.statusid}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input forprop="startDate" fluid placeholder='mm/dd/yyyy' type='date' onChange={this.updateSearchParam} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input forprop="endDate" fluid placeholder='mm/dd/yyyy' type='date' onChange={this.updateSearchParam} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Button color="blue" onClick={this.getTestletsReport}>Search</Button>
                                <Popup content={ `Download Testlet Usage CSV`} trigger={
                                    <Button onClick={this.downloadTestletsReportCSV} circular icon='cloud download' color='green'></Button>
                                }/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                {testletsreport ?
                    <Segment attached>
                        <p>Testlets</p>
                        <Table compact celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Testlet</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                    <Table.HeaderCell>Exposure</Table.HeaderCell>
                                    <Table.HeaderCell>Min Time Spent</Table.HeaderCell>
                                    <Table.HeaderCell>Max Time Spent</Table.HeaderCell>
                                    <Table.HeaderCell>Mean Time Spent</Table.HeaderCell>
                                    <Table.HeaderCell>Median Time Spent</Table.HeaderCell>
                                    <Table.HeaderCell>Average Difficulty</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {testletsreport.map((item, item_index)=>{
                                    return (
                                        <Table.Row key={`item-row-${item_index}`}>
                                            <Table.Cell>
                                                {item.title ? item.title : 'NA'}
                                                <br/>
                                                <small className="smallid">{/*itemid*/}</small>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.status ? item.status : 'NA'}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.exposure != null ? item.exposure : 'NA'}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.min_val != null ? item.min_val : 'NA'}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.max_val != null ? item.max_val : 'NA'}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.mean_val != null ? item.mean_val : 'NA'}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.median_val != null ? item.median_val : 'NA'}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.difficulty != null ? item.difficulty : 'NA'}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    </Segment>
                :null}
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(TestletsReport));
